import React, { useState, createRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"
import { useField, useFormikContext } from "formik"
import { Close as Clear } from "theme-ui"
import {
  TextInput,
  SearchIcon,
  rawColorValues,
  Colors,
  Fonts,
  FontWeight
} from "@sixty-six-north/ui-system"
import { useSupportedCountries } from "i18n/SupportedCountriesProvider"
import { usePreferredLanguage } from "i18n/StoreHooks"
import {
  getAddressValuesForGooglePlace,
  usePlacesAutocomplete
} from "utils/AddressUtils"
export const GooglePlacesSearchDropdown = ({
  onPlaceSelectedStart,
  onPlaceSelectedEnd
}) => {
  const { t } = useTranslation("checkout")
  const dropdownId = uuid()

  const supportedCountries = useSupportedCountries()
  const language = usePreferredLanguage()

  const supportedCountryCodes = supportedCountries.map(c => c.code)
  const [searchCountry] = useField("searchCountry")
  const [searchValue, setSearchValue] = useState("")

  const [resultFocussedIndex, setResultFocussedIndex] = useState<number>(-1)
  const [showResults, setShowResults] = useState(true)
  const [showClear, setShowClear] = useState(false)
  const searchCountryValue = searchCountry.value
  const { setFieldValue } = useFormikContext<{
    streetName: string
    postalCode: string
    city: string
    country: string
    state: string
    region: string
  }>()

  const options: {
    types: string[]
    componentRestrictions?: google.maps.places.ComponentRestrictions
    language: string | null
  } = {
    types: ["address"],
    language: language
  }
  const inputRef = createRef<HTMLInputElement>()
  const wrapperRef = createRef<HTMLDivElement>()

  if (searchCountryValue)
    options.componentRestrictions = { country: searchCountryValue }
  else if (supportedCountryCodes.length <= 5) {
    // Google limitation: max 5 countries supported
    options.componentRestrictions = { country: supportedCountryCodes }
  }

  const {
    placesService,
    predictions,
    isPlacesAutocompleteLoading,
    placesAutocompleteHasError
  } = usePlacesAutocomplete({
    ...options,
    input: searchValue
  })

  const onPlaceSelected = (
    place: google.maps.places.AutocompletePrediction
  ) => {
    setSearchValue(place.description)
    setShowResults(false)

    placesService?.getDetails({ placeId: place.place_id }, placeDetails => {
      onPlaceSelectedStart()
      const { streetName, postalCode, country, city, state, region } =
        getAddressValuesForGooglePlace(placeDetails, language)

      setFieldValue("streetName", streetName)
      setFieldValue("additionalStreetInfo", "")
      setFieldValue("postalCode", postalCode)
      setFieldValue("country", country)
      setFieldValue("city", city)
      if (state && country === "US") setFieldValue("state", state)
      if (region && country === "CA") setFieldValue("region", region)
      onPlaceSelectedEnd()
      setShowClear(true)
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    setShowResults(true)
  }

  const handleClear = () => {
    setSearchValue("")
    setShowClear(false)
    inputRef.current?.focus()
  }

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        wrapperRef.current &&
        event.target &&
        !wrapperRef.current.contains(event.target)
      ) {
        hideDropdown()
      }
    }
    document.addEventListener("mousedown", handleOutsideClick)
    return () => document.removeEventListener("mousedown", handleOutsideClick)
  })

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "ArrowUp": {
        setResultFocussedIndex(current => (current > 0 ? current - 1 : 0))
        event.preventDefault()
        break
      }

      case "ArrowDown": {
        setResultFocussedIndex(current =>
          current < predictions.length - 1
            ? current + 1
            : predictions.length - 1
        )
        event.preventDefault()
        break
      }

      case "Enter": {
        resultFocussedIndex !== -1 &&
          onPlaceSelected(predictions[resultFocussedIndex])
        event.preventDefault()
        break
      }
      case "Escape": {
        hideDropdown()
        event.preventDefault()
        break
      }
    }
  }

  const hideDropdown = () => {
    setShowResults(false)
    setResultFocussedIndex(-1)
  }

  return (
    <div ref={wrapperRef}>
      <TextInput
        ref={inputRef}
        role="combobox"
        name="searchAddress"
        autoComplete="new-password"
        aria-controls={dropdownId}
        aria-expanded={showResults}
        aria-activedescendant={
          resultFocussedIndex !== -1
            ? `${dropdownId}-${resultFocussedIndex}`
            : undefined
        }
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setShowResults(true)}
      >
        {t("searchAddress")}
      </TextInput>
      {!showClear && (
        <SearchIcon sx={{ position: "absolute", top: 16, right: 10 }} />
      )}
      {showClear && (
        <Clear
          type="button"
          aria-label={t("clear")}
          title={t("clear")}
          sx={{
            position: "absolute",
            top: 14,
            right: 6,
            height: 20,
            width: 20,
            cursor: "pointer"
          }}
          onClick={handleClear}
        />
      )}
      <div
        hidden={
          !showResults ||
          placesAutocompleteHasError ||
          searchValue.length === 0 ||
          predictions.length === 0
        }
        sx={{
          border: `1px solid ${rawColorValues[Colors.grey][2]}`,
          background: `${Colors.white}`,
          borderTop: "none",
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2
        }}
      >
        <ul
          id={dropdownId}
          role="listbox"
          aria-label={t("searchAddress")}
          sx={{
            listStyle: "none",
            mb: 16,
            p: 8
          }}
        >
          {predictions.map((item, index) => (
            <li
              key={item.place_id}
              role="option"
              sx={{
                width: "100%"
              }}
            >
              <div
                id={`${dropdownId}-${index}`}
                sx={{
                  fontFamily: Fonts.text,
                  fontWeight: FontWeight.textRegular,
                  fontSize: "scale-1",
                  appearance: "none",
                  border: "none",
                  textAlign: "left",
                  width: "100%",
                  m: 0,
                  p: 8,
                  borderRadius: 2,
                  background:
                    resultFocussedIndex === index
                      ? rawColorValues[Colors.grey][4]
                      : "none"
                }}
                onClick={() => onPlaceSelected(item)}
                onMouseEnter={() => setResultFocussedIndex(index)}
              >
                {item.description}
              </div>
            </li>
          ))}
        </ul>
        <span
          sx={{
            position: "absolute",
            right: 4,
            bottom: 4,
            display: "flex",
            alignItems: "center",
            gap: 4,
            fontSize: "scale-2"
          }}
        >
          Powered by
          <img src="/google_on_white.png" alt="Google" />
        </span>
      </div>

      {showResults &&
        searchValue.length > 0 &&
        !placesAutocompleteHasError &&
        !isPlacesAutocompleteLoading &&
        predictions.length === 0 && (
          <div
            sx={{
              border: `1px solid ${rawColorValues[Colors.grey][2]}`,
              borderTop: "none",
              fontSize: "scale-1",

              borderBottomLeftRadius: 2,
              borderBottomRightRadius: 2,
              px: 16,
              py: 8
            }}
          >
            <p>{t("noAddressMatchedSearch")}</p>
            <p>{t("enterManually")}</p>
          </div>
        )}

      {placesAutocompleteHasError && (
        <div
          sx={{
            border: `1px solid ${rawColorValues[Colors.grey][2]}`,
            borderTop: "none",
            fontSize: "scale-1",

            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            px: 16,
            py: 8
          }}
        >
          <p>{t("addressAPIError")}</p>
          <p>{t("pleaseTryAgainManually")}</p>
        </div>
      )}
    </div>
  )
}
